/**
 * Use like this:
 * ```svelte
 * import { scroll } from '$lib/stores/listeners';
 * $: ({ scrollY, scrollingUp } = $scroll);
 * ```
 */

import { browser } from "$app/environment"
import { toasts } from "$lib/components/Toasts.svelte"
import { derived, readable } from "svelte/store"

const startY = browser ? window.scrollY : 0
const pointer = {
    scrollY: startY,
    lastScrollY: startY,
    scrollingUp: false
}

export const scroll = readable(pointer, (set) => {
    if (!browser) return

    function _set() {
        const { scrollY } = window
        // Update pointer values before setting the store
        const scrollingUp = scrollY <= pointer.scrollY

        // Update the store with the new values
        set({
            scrollY,
            scrollingUp,
            lastScrollY: pointer.scrollY
        })

        // Update the pointer for the next scroll event
        pointer.scrollY = scrollY
        pointer.scrollingUp = scrollingUp
    }

    // Add scroll event listener
    window.addEventListener("scroll", _set, { passive: true })

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("scroll", _set)
})

export const isHeaderShown = derived(scroll, ($) => $.scrollY <= 64 || $.scrollingUp)
export const isFABReturnTopShown = derived([scroll, toasts], ([$scroll, $toasts]) => $toasts.length == 0 && $scroll.scrollY > 50)