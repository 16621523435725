<script lang="ts">
	import { theme } from '$lib/stores/theme';
	import ThemeController from '$lib/components/layout/ThemeController.svelte';
	import { cn } from '$lib/utils/style';
	import Icon from '@iconify/svelte';
	import Logo from './Logo.svelte';
	import { flyAndScale } from '$lib/utils/transitionts';
	import { PUBLIC_DASHBOARD_BASE_URL } from '$env/static/public';
	import { isFABReturnTopShown, isHeaderShown } from '$lib/stores/listeners';

	let className = '';
	export { className as class };
</script>

<div
	class={cn(
		'fixed top-0 left-0 right-0',
		'transition-transform will-change-transform',
		$isHeaderShown ? 'translate-y-0' : '-translate-y-full',
		className
	)}
>
	<header
		class="bg-base-100/60 backdrop-blur-sm border-b border-base-content/10 w-screen h-16"
	>
		<div class="navbar px-8 max-w-screen-2xl mx-auto justify-between">
			<div>
				<Logo />
			</div>

			<div class="flex items-center">
				<a href="/features/" class="link link-hover mr-6 hidden sm:inline-flex">Features</a>
				<a href="/blog/" class="link link-hover mr-6 hidden sm:inline-flex">Blog</a>
				<a href="/free-tools/" class="link link-hover mr-6 hidden sm:inline-flex">Blog</a>
				<a href="/changelog/" class="link link-hover mr-6 hidden sm:inline-flex">Changelog</a>
				<a href={PUBLIC_DASHBOARD_BASE_URL} target="_blank" class="btn btn-sm mr-2">Log In</a>
				<a href="/#pricing" class="btn btn-sm btn-primary mr-2">
					<span> Get started </span>
				</a>
				<button
					type="button"
					class="btn btn-sm btn-square btn-ghost"
					on:click={theme.toggle}
				>
					<ThemeController />
				</button>
			</div>
		</div>
	</header>
</div>

{#if $isFABReturnTopShown}
	<button
		transition:flyAndScale={{
			duration: 200,
			start: 0.5,
			y: 50
		}}
		type="button"
		class="sm:hidden fixed bottom-4 right-2 btn btn-secondary border-0 bg-opacity-60 backdrop-blur-md btn-circle"
		style:z-index={80}
		on:click={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
	>
		<Icon icon="majesticons:chevron-up" width="36" height="36" />
	</button>
{/if}
