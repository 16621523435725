import { addToastComponent, removeToast } from '$lib/components/Toasts.svelte';
import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import CookiesBanner from '$lib/components/cookies/CookiesBanner.svelte';
import CookiesCheckboxes from '$lib/components/cookies/CookiesCheckboxes.svelte';

type CookieConsent = {
	necessary: boolean;
	analytics: boolean;
};

const { subscribe, set, update } = writable<CookieConsent>({
	necessary: true,
	analytics: true
});

const COOKIE_NAME = 'cookie-consent';

export const cookies = {
	subscribe,
	init() {
		if (!browser) return;

		const cookieConsent = getCookie(COOKIE_NAME);
		if (!cookieConsent) {
			this.addBanner();
			return;
		}

		try {
			const parsed: CookieConsent = JSON.parse(cookieConsent);

			// Extract only what cared for (after the JSON.parse, the cookie may still contain other data manually injected by the user.)
			const { necessary, analytics } = parsed;

			set({ necessary, analytics });
		} catch (err) {
			console.error(err);
			deleteCookie(COOKIE_NAME);
		}
	},
	toggleAnalytics() {
		update((state) => ({ ...state, analytics: !state.analytics }));
	},
	addBanner() {
		addToastComponent('cookies-banner', CookiesBanner, {
			close: false
		});
	},
	addCheckboxes() {
		removeToast('cookies-banner');
		setTimeout(() => addToastComponent('cookies-checkboxes', CookiesCheckboxes), 300);
	},
	accept() {
		createCookie(true);
		removeToast('cookies-banner');
		set({ necessary: true, analytics: true });
	},
	cancel() {
		removeToast('cookies-checkboxes');
	},
	save() {
		update((state) => {
			createCookie(state.analytics);
			return state;
		});
		removeToast('cookies-checkboxes');
	}
};

const necessary = true;
function createCookie(analytics: boolean) {
	document.cookie = `cookie-consent=${JSON.stringify({ necessary, analytics })}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
}

function getCookie(name: string) {
	name = name + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
	}
	return null;
}

function deleteCookie(name: string) {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
